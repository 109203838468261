import { faLocationDot } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Checkbox, Input, InputNumber } from 'antd';
import Field from 'components/Form/Field';
import WithFormHooksController from 'components/common/WithFormHooksController';
import { useFormHooks } from 'context/FormHooksContext';
import React, { useEffect } from 'react';
import { useWatch } from 'react-hook-form';
import usePlacesStore from '../../../store';
import MapView from '../MapView';
import FormControlledDivisionSelect from './FormControlledDivisionSelect';
import FormControlledMapAddressAutoComplete from './FormControlledMapAddressAutoComplete';

export default function DetailsForm() {
  const { setValue, control } = useFormHooks();
  const selectedPlace = usePlacesStore(state => state.selectedPlace);
  let initialMapView = null;

  if (selectedPlace?.longitude && selectedPlace?.latitude) {
    initialMapView = {
      longitude: selectedPlace.longitude,
      latitude: selectedPlace.latitude,
    };
  }

  const isCustomizedDocket = useWatch({
    control,
    name: 'isCustomizedDocket',
  });

  useEffect(() => {
    if (selectedPlace) {
      const fieldValues = { ...selectedPlace };
      [
        'address1',
        'locality',
        'postCode',
        'region',
        'regionCode',
        'country',
        'countryCode',
      ].forEach(fieldName => {
        if (fieldValues[fieldName]) setValue(fieldName, fieldValues[fieldName]);
      });
    } else {
      [
        'address1',
        'locality',
        'postCode',
        'region',
        'regionCode',
        'country',
        'countryCode',
      ].forEach(fieldName => {
        setValue(fieldName, null);
      });
    }
  }, [selectedPlace]);

  return (
    <div className="grid gap-3 grid-cols-12 h-auto px-3 mt-3 mb-3">
      <Field label="Name" isRequired span={6}>
        <WithFormHooksController
          name="name"
          control={control}
          component={<Input className="px-2 rounded-md" />}
        />
      </Field>
      <Field label="Display Name" isRequired span={6}>
        <WithFormHooksController
          control={control}
          name="displayName"
          component={<Input className="px-2 rounded-md" />}
        />
      </Field>
      <Field label="Location" isRequired span={6}>
        <FormControlledMapAddressAutoComplete
          name="location"
          control={control}
        />
      </Field>
      <Field label="Division">
        <FormControlledDivisionSelect
          control={control}
          name="divisionId"
          onChange={value => setValue('divisionId', value)}
          componentProps={{
            allowClear: true,
            showSearch: true,
          }}
        />
      </Field>
      <div
        className="flex flex-col"
        style={{ gridColumn: `span 12 / span 12` }}
      >
        <MapView className="h-[200px]" {...initialMapView} />
        <div className="flex items-center p-2">
          <span className="flex mr-1 ">
            <FontAwesomeIcon
              icon={faLocationDot}
              style={{
                fontSize: '16px',
                color: '#A8AEB8',
                opacity: 0.5,
              }}
            />
          </span>
          <span className="text-blue-500 text-[.7rem] truncate">
            {selectedPlace?.placeName ?? 'No location selected'}
          </span>
        </div>
      </div>
      <Field label="Address" span={4}>
        <WithFormHooksController
          control={control}
          name="address1"
          component={<Input className="px-2 rounded-md" disabled />}
        />
      </Field>
      <Field label="Suburb" span={4}>
        <WithFormHooksController
          control={control}
          name="locality"
          component={<Input className="px-2 rounded-md" disabled />}
        />
      </Field>

      <Field label="Post Code" span={4}>
        <WithFormHooksController
          control={control}
          name="postCode"
          component={<Input className="px-2 rounded-md" disabled />}
        />
      </Field>

      <Field label="Region" span={6}>
        <WithFormHooksController
          control={control}
          name="region"
          component={<Input className="px-2 rounded-md" disabled />}
        />
      </Field>

      <Field label="Region Code" span={6}>
        <WithFormHooksController
          control={control}
          name="regionCode"
          component={<Input className="px-2 rounded-md" disabled />}
        />
      </Field>

      <Field label="Country" span={6}>
        <WithFormHooksController
          control={control}
          name="country"
          component={<Input className="px-2 rounded-md" disabled />}
        />
      </Field>

      <Field label="Country Code" span={6}>
        <WithFormHooksController
          control={control}
          name="countryCode"
          component={<Input className="px-2 rounded-md" disabled />}
        />
      </Field>

      <Field label="Customize Docket" span={2} tooltip="Customize Auto Docket">
        <WithFormHooksController
          control={control}
          name="isCustomizedDocket"
          component={
            <Checkbox
              className="px-2 rounded-md"
              checked={isCustomizedDocket}
            />
          }
        />
      </Field>
      {isCustomizedDocket && (
        <>
          <Field label="Auto docket Prefix" span={5}>
            <WithFormHooksController
              control={control}
              name="docketPrefix"
              component={<Input className="px-2 rounded-md" />}
            />
          </Field>

          <Field label="Range Start" span={5}>
            <WithFormHooksController
              control={control}
              name="rangeStart"
              component={
                <InputNumber className="px-2 rounded-md w-full" min={0} />
              }
            />
          </Field>
        </>
      )}
    </div>
  );
}
