import { Button, Tooltip } from 'antd';
import WarningAbandon from 'components/common/WarningAbandon';
import WithThemeProvider from 'components/providers/WithThemeProvider';
import { debounce } from 'lodash';
import React from 'react';

interface IProps {
  isAbandoning: boolean;
  isLoading?: boolean;
  onCancel: () => void;
  onSave: () => void;
  onConfirm?: () => void;
  displayConfirm?: boolean;
  isConfirming?: boolean;
  disabled?: boolean;
}

const FormUserActions = ({
  onSave,
  onCancel,
  isAbandoning,
  isLoading,
  onConfirm,
  displayConfirm,
  isConfirming,
  disabled,
}: IProps) => {
  return (
    <div className="w-full px-4 py-3 border-t border-solid border-gray-200">
      <WithThemeProvider token={{ colorPrimary: '#fff' }}>
        <div className="flex justify-end">
          {displayConfirm ? (
            <Tooltip
              placement="topLeft"
              title={"It changes the status to 'NEW'"}
              color="black"
            >
              <Button
                onClick={onConfirm}
                loading={isConfirming}
                className="mr-2 bg-blue-600 text-white hover:text-white hover:bg-mainOrangeHover"
              >
                Confirm
              </Button>
            </Tooltip>
          ) : null}
          {isAbandoning ? <WarningAbandon /> : null}
          <Button
            onClick={onCancel}
            className={
              isAbandoning
                ? 'bg-gray-400 text-white hover:bg-red-600 mr-2'
                : 'bg-gray-100 border-0 text-gray-900 hover:bg-gray-400 mr-2'
            }
          >
            {isAbandoning ? 'Discard' : 'Cancel'}
          </Button>
          <Button
            onClick={debounce(onSave, 500)}
            loading={isLoading}
            disabled={disabled}
            className="bg-mainOrange text-white hover:text-white hover:bg-mainOrangeHover"
          >
            Save
          </Button>
        </div>
      </WithThemeProvider>
    </div>
  );
};

export default FormUserActions;
